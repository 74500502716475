<!-- Login.vue -->
<template>
  <div class="login-container">
    <h2>账号登录</h2>
    <input v-model="phoneNumber" placeholder="输入手机号" />
    <input v-model="code" placeholder="输入验证码" />
    <button :disabled="isDisabled" @click="sendVerificationCode(phoneNumber)">{{ buttonText }}</button>
    <button @click="login">登录</button>
  </div>
</template>

<script>
import axios from 'axios';
import store from '../store';

axios.defaults.baseURL = 'https://www.fuxiyixue.cn';

export default {
  name: 'LoginPage',
  data() {
    return {
      phoneNumber: '',
      code: '',
      isDisabled: false, // 按钮是否禁用
      countdown: null, // 倒计时定时器
      buttonText: '发送验证码',
    };
  },
  methods: {
    async login() {
      if (!this.phoneNumber || !this.code) {
        alert('手机号和验证码不能为空');
        return false;
      }
      // await this.$store.dispatch('verifyCredentials', { phone: this.phone, code: this.code });
      // if (this.$store.state.isAuthenticated) {
      //   this.$router.push('/courses'); // 重定向到课程列表页面
      // }
      console.log("登录中...");
      console.log("state=", store.state)
      this.loginWithVerificationCode(this.phoneNumber, this.code);
    },

    async sendVerificationCode(phoneNumber) {
      // 判断手机号是否满足要求
      if (!/^1[3456789]\d{9}$/.test(phoneNumber)) {
        alert('请输入有效的手机号码');
        return;
      }
      // 设置按钮为禁用
      this.isDisabled = true;
      this.countdown = 60; // 设置倒计时为60秒
      this.buttonText = `发送中...`;
      // 使用 setInterval 递减倒计时并更新按钮文本
      const interval = setInterval(() => {
          if (this.countdown === 0) {
            clearInterval(interval); // 停止 setInterval
            this.isDisabled = false; // 恢复按钮
            this.buttonText = '发送验证码';
          } else {
            this.countdown--; // 减少倒计时
            this.buttonText = `${this.countdown} 秒后重试`;
          }
        }, 1000); // 每秒更新一次
      
      // 发送 POST 请求以获取验证码，同时附带 phoneNumber 查询参数
      axios.post('/user_center/v1/verification_code', null, {
        params: {
          phoneNumber: phoneNumber, // 查询参数
        },
      })
      .then(response => {
        // 成功处理响应
        console.log("发送验证码返回：", response.data);
        return response.data;
      })
      .catch(error => {
        console.log("发送验证码错误:", error);
        // 错误处理
        console.error(error);
      });
    },

    async loginWithVerificationCode(phoneNumber, code) {
      axios.post('/user_center/v1/login_with_code', {
        phoneNumber: phoneNumber,
        code: code
        }, {
            headers: {
            'Content-Type': 'application/json' // 确保这是服务器期望的类型
          }
      })
      .then(response => {
        // 成功处理响应
        let ret = response.data
        store.state.isAuthenticated = true;
        store.state.token = ret.data.token;
        this.$router.push('/courses'); // 重定向到课程列表页面
      })
      .catch(error => {
        console.log("登录错误:", error);
        // 错误处理
        console.error(error);
        alert("登录失败");
      });
    },
  }
};

</script>

<style>
.login-container {
  display: flex;
  /* 使用Flexbox布局 */
  flex-direction: column;
  /* 设置为上下结构排列 */
  max-width: 300px;
  /* 容器的最大宽度 */
  margin: auto;
  /* 水平居中 */
  padding: 20px;
  /* 内边距 */
  border: 1px solid #ccc;
  /* 边框 */
  border-radius: 5px;
  /* 边框圆角 */
}

.login-container h2 {
  margin-bottom: 20px;
  /* 标题与输入框之间的间距 */
  color: #141414;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
}

.login-container input {
  margin-bottom: 10px;
  /* 输入框之间的间距 */
  padding: 10px;
  /* 输入框内边距 */
  border: 1px solid #ccc;
  /* 输入框边框 */
  border-radius: 5px;
  /* 输入框边框圆角 */
  font-size: 16px;
  /* 输入框字体大小 */
}

.login-container button {
  margin-bottom: 10px;
  padding: 10px;
  /* 按钮内边距 */
  border: none;
  /* 移除按钮边框 */
  border-radius: 5px;
  /* 按钮边框圆角 */
  background-color: #007bff;
  /* 按钮背景颜色 */
  color: white;
  /* 按钮文字颜色 */
  font-size: 16px;
  /* 按钮字体大小 */
  cursor: pointer;
  /* 鼠标悬停效果 */
}
</style>