
<template>
  <div class="appHeader">
    <div class="content">
      <div class="left">
        <img class="img" src="../assets/logo.png" alt />
        <div class="text">
          <div class="title">伏羲医学</div>
          <div style="color: #969696 ;font-size: 12px ;margin-top: 6px;">弘 扬 中 医 文 化</div>
          <div style="color: #969696;font-size: 12px ">引 领 中 医 全 球 化</div>
        </div>
      </div>
      <div class="center">
        <div
          :class="{ 'tab active': state.active === index, 'tab': state.active !== index }"
          v-for="(item,index) in state.tabList"
          @click="navigateTo(item.name,index)"
          :key="index"
        >{{ item.tab }}</div>
      </div>
      <div style="position: relative;">
        <div class="right" @click="clickRight">
          <!-- <img class="autorImg" src="../assets/autorImg.png" alt /> -->
          <!-- <div>{{state.userInfoData.nickname||'登录 | 注册'}}</div> -->
          <img
            v-if="state.userInfoData.nickname && !state.menuVisible"
            class="upDown"
            src="../assets/down.png"
            alt
          />
          <img
            v-if="state.userInfoData.nickname && state.menuVisible"
            class="upDown"
            src="../assets/up.png"
            alt
          />
        </div>
        <div class="menu" v-if="state.menuVisible && state.userInfoData.nickname">
          <div
            :style="state.menuIndex===index&&state.isMouseover ? 'background:#d8f9ee':''"
            @mouseover="menuMouseover(index)"
            @mouseleave="state.isMouseover=false"
            class="menuItem"
            @click="menuClick(item)"
            v-for="(item,index) in state.menuList"
            :key="index"
          >{{ item }}</div>
        </div>
      </div>
      <el-dialog
        v-model="state.dialogVisible"
        :title="state.isPasswordLogin?'密码登录':'验证码登录'"
        width="40%"
      >
        <div style="width: 94%;margin-left: 3%;">
          <div class="tipsBox" v-if="state.errorText">
            <div class="tipCOntent">
              <div>{{ state.errorText }}</div>
              <img @click="closeErrorText" class="colseTips" src="../assets/colseTips.png" alt />
            </div>
          </div>
          <el-form :modelValue="form" style="margin-top: 33px;">
            <el-form-item>
              <el-input style="height: 73px;" v-model="state.phoneNumber" placeholder="请输入手机号码" />
            </el-form-item>
            <el-form-item v-if="!state.isPasswordLogin" style="margin-top: 33px;">
              <div style="display: flex;width: 100%;">
                <el-input
                  style="height: 73px; width: 60%;"
                  v-model="state.code"
                  placeholder="请输入验证码"
                />
                <button
                  :class="state.isSend ?'loginBtn sendCodeBtn send' :'loginBtn sendCodeBtn'"
                  :disabled="state.isSend"
                  @click="sendCode"
                >{{ state.codeText }}</button>
              </div>
            </el-form-item>
            <el-form-item v-if="state.isPasswordLogin">
              <div class="passwordBox">
                <el-input
                  style="height: 73px;"
                  :type="state.passwordType? 'password' :'text'"
                  v-model="state.password"
                  placeholder="8-32位，须包含数字和字母至少两种或以上"
                ></el-input>
                <img
                  @click="inputTypeCheck"
                  v-if="!state.passwordType"
                  class="eye"
                  src="../assets/eye.png"
                  alt
                />
                <img
                  v-if="state.passwordType"
                  @click="inputTypeCheck"
                  class="closeEye"
                  src="../assets/closeEye.png"
                  alt
                />
              </div>
            </el-form-item>
            <!-- <el-form-item style="margin-top: 33px;" @click="login">
              <div class="loginBtn">登录</div>
            </el-form-item>
            <el-form-item v-if="!state.isPasswordLogin" style="margin-top: 33px;">
              <div class="passwordText" @click="checkLoginType">密码登录</div>
            </el-form-item>
            <el-form-item v-if="state.isPasswordLogin" style="margin-top: 20px;">
              <div
                style="width: 100%;font-size: 24px; display: flex;justify-content: space-between;"
              >
                <span style="color: #40cb75;" @click="checkLoginType">验证码登录</span>
                <span style="color: #909090;">忘记密码？</span>
              </div>
            </el-form-item> -->
          </el-form>
          <div class="dialogBottom">
            <div>未注册手机验证后自动创建账号</div>
            <div>
              登陆注册即表示同意
              <span>《用户协议》</span>和
              <span>《隐私政策》</span>
            </div>
          </div>
        </div>
      </el-dialog>
      <div v-if="state.isSuccess" class="message">登录成功</div>
    </div>
  </div>
</template>

<script setup>
// import { userInfo } from '@/request/home'
// import { codeLogin, passwordLogin, sendVerificationCode } from '@/request/login'

import { sendVerificationCode } from '@/request/login'
// import { removeToken, setToken } from '@/utils/api'
import { removeToken } from '@/utils/api'
import { defineComponent, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
defineComponent({
  name: 'appHeader'
})
const state = reactive({
  tabList: [
    { tab: '首页', value: 1, name: 'home' },
    // { tab: '免费试听', value: 2, name: 'video' },
    // { tab: '直播', value: 3, name: 'video' },
    { tab: '在线课程', value: 2, name: 'courses' },
    { tab: '下载APP', value: 3, name: 'downLoadAPP' },
    { tab: '关于我们', value: 4, name: 'about' }
  ],
  menuList: ['个人信息', '我的课程', '我的订单', '退出登录'],
  active: 0,
  phoneNumber: '',
  password: '',
  dialogVisible: false,
  menuVisible: false,
  passwordType: true,
  isPasswordLogin: false,
  isSuccess: false,
  countDown: 60,
  codeText: '获取验证码',
  isSend: false,
  errorText: '',
  code: '',
  userInfoData: {},
  menuIndex: '',
  isMouseover: false
})
onMounted(() => {
  const userInfoData = localStorage.getItem('userInfoData')
  state.userInfoData = JSON.parse(userInfoData) || ''
})
const router = useRouter()
const navigateTo = (name, index) => {
  state.active = index
  router.push({ name: name, query: { index: index } })
}
// const checkLoginType = () => {
//   state.isPasswordLogin = !state.isPasswordLogin
//   state.errorText = ''
// }
const inputTypeCheck = () => {
  state.passwordType = !state.passwordType
}
const clickRight = () => {
  if (state.userInfoData.nickname) {
    state.menuVisible = !state.menuVisible
  } else {
    state.dialogVisible = true
  }
}

const closeErrorText = () => {
  state.errorText = ''
}

// 发送验证码
const sendCode = (e) => {
  const phoneNumberCount = state.phoneNumber.length
  if (phoneNumberCount !== 11) {
    state.errorText =
      phoneNumberCount === 0
        ? '请输入手机号码'
        : '手机号码格式不正确，请重新输入'

    //阻止默认事件
    e.preventDefault()
    return
  }
  state.isSend = true
  const timer = setInterval(() => {
    state.countDown--
    state.codeText = `${state.countDown}秒后重发`
    if (state.countDown === 0) {
      clearInterval(timer)
      state.countDown = 60
      state.codeText = `重新发送`
      state.isSend = false
    }
  }, 1000)
  sendVerificationCode({ phoneNumber: state.phoneNumber }).then((res) => {
    if (res.code === 200) {
      state.isSuccess = true
      setTimeout(() => {
        state.isSuccess = false
      }, 1000)
    }
  })
}

// 登录
// const login = () => {
//   const phoneNumberCount = state.phoneNumber.length
//   const codeCount = state.code.length
//   if (phoneNumberCount !== 11) {
//     state.errorText =
//       phoneNumberCount === 0
//         ? '请输入手机号码'
//         : '手机号码格式不正确，请重新输入'
//     return
//   }
//   if (state.isPasswordLogin) {
//     if (!state.password) {
//       state.errorText = '请输入密码'
//       return
//     }
//     const params = {
//       phoneNumber: state.phoneNumber,
//       password: state.password
//     }
//     try {
//       passwordLogin(params).then((res) => {
//         if (res.code === 200) {
//           loginSuccess(res.data.token)
//         }
//       })
//     } catch (e) {
//       console.log(e)
//     }
//   } else {
//     if (codeCount !== 6) {
//       state.errorText = codeCount === 0 ? '请输入验证码' : '验证码格式不正确'
//       return
//     }
//     const params = {
//       phoneNumber: state.phoneNumber,
//       code: state.code
//     }
//     try {
//       codeLogin(params).then((res) => {
//         if (res.code === 200) {
//           loginSuccess(res.data.token)
//         }
//       })
//     } catch (e) {
//       console.log(e)
//     }
//   }
// }

// 获取个人信息
// const getUserInfo = () => {
//   try {
//     userInfo().then((res) => {
//       if (res.code === 200) {
//         state.userInfoData = res.data
//         console.log(res.data)
//         localStorage.setItem('userInfoData', JSON.stringify(res.data))
//       }
//     })
//   } catch (e) {
//     console.log(e)
//   }
// }
// 登录成功后
// const loginSuccess = (token) => {
//   setToken(token)
//   getUserInfo()
//   state.dialogVisible = false
//   state.isSuccess = true
//   setTimeout(() => {
//     state.isSuccess = false
//   }, 1000)
//   state.phoneNumber = ''
//   state.password = ''
// }
const menuMouseover = (index) => {
  state.menuIndex = index
  state.isMouseover = true
}
const menuClick = (item) => {
  state.menuVisible = false
  if (item === '退出登录') {
    localStorage.removeItem('userInfoData')
    state.userInfoData = {}
    removeToken()
  } else {
    router.push({ name: 'menu', query: { item: item } })
  }
}
</script>

<style scoped lang="scss">
.appHeader {
  width: 70%;
  margin-left: 14%;
  padding-bottom: 16px;
}
.content {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-around;
}
.title {
  color: #40cb75;
  font-size: 18px;
  font-weight: 600;
}
.activeLine {
  width: 50px;
  height: 3px;
  background: #40cb75;
}
.img {
  width: 60px;
  height: 60px;
}

.left {
  display: flex;
}
.right {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.autorImg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.upDown {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 10px;
}
.center {
  display: flex;
  justify-content: space-around;
  width: 50%;
  height: 40px;
}
.tab {
  font-family: Source Han Sans CN;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  color: #3d3d3d;
  cursor: pointer;
}
.active {
  border-bottom: 3px solid #40cb75;
  color: #40cb75;
}
.line {
  width: 100%;
  height: 1px;
  background: #d5d5d5;
}
.text {
  margin-left: 20px;
}
.tipsBox {
  width: 100%;
  height: 48px;
  color: #ffffff;
  border-radius: 5px;
  background: #fa5151;
  .tipCOntent {
    width: 94%;
    margin-left: 3%;
    height: 48px;
    line-height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .colseTips {
      width: 24px;
      height: 24px;
    }
  }
}

.loginBtn {
  width: 100%;
  height: 73px;
  border-radius: 5px;
  text-align: center;
  line-height: 73px;
  color: #ffffff;
  background: #40cb75;
  font-size: 24px;
  border: none;
}
.sendCodeBtn {
  margin-left: 20px;
  width: 40%;
  border: none;
}
.send {
  border: none;
  background: #d1d1d1;
}
.passwordText {
  font-size: 24px;
  color: #40cb75;
  width: 100%;
  text-align: center;
  height: 73px;
  line-height: 73px;
}
.dialogBottom {
  color: #c5c5c5;
  font-size: 24px;
  margin-top: 100px;
  span {
    color: #40cb75;
  }
}
.passwordBox {
  width: 100%;
  position: relative;
  .closeEye {
    width: 20px;
    height: 14px;
    position: absolute;
    right: 20px;
    top: 30px;
  }
  .eye {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 14px;
    top: 24px;
  }
}
.message {
  position: absolute;
  top: 340px;
  left: 43%;
  background-color: #40cb75;
  width: 203px;
  height: 52px;
  border-radius: 5px;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  line-height: 52px;
}
.menu {
  width: 137px;
  z-index: 999;
  height: 221px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 50px;
  left: 10px;
  .menuItem {
    width: 137px;
    height: 39px;
    cursor: default;
    line-height: 39px;
    font-size: 20px;
    color: #3d3d3d;
    text-align: center;
    margin: 12px 0;
  }
}
</style>
