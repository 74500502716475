<template>
  <div class="page">
    <!-- <div class="carousel-example">
      <CarouselExample />
    </div> -->
    <div class="box">
      <div class="title">
        <div style="display: flex;">
          <div class="bigGreen"></div>
          <div class="titleText">关于伏羲医学</div>
        </div>
      </div>
      <div class="text">
        <p>伏羲医学是一款专为医疗从业人员打造的全方位教学服务平台，旨在提供高质量的学习资源，帮助有效提升医考通过率。</p>
        <p>多元化学习资源：提供临床案例、医学知识点、病例分析、手术视频等多样化学习资源，帮助您全面了解医学知识。</p>
        <p>个性化学习计划：根据您的需求和水平，定制学习计划，提供有针对性的学习材料和习题，帮助您有序、高效地提升知识水平。</p>
        <p>实时答疑解惑：专业导师团队提供实时答疑服务，解决您学习中的疑惑，确保您的学习顺利进行。</p>
        <p>模拟考试训练：提供模拟考试功能，熟悉考试环境和题型，检验学习成果，让您更自信地迎接医考挑战。</p>
        <p>社区互动交流：与其他医疗从业人员交流、分享学习心得、经验和资源，共同进步。</p>
      </div>
    </div>
    <appFooter></appFooter>
  </div>
</template>

<script setup >
import 'swiper/css'
import { defineComponent } from 'vue'
import 'vue3-carousel/dist/carousel.css'
// import CarouselExample from '../components/CarouselExample.vue'
import appFooter from '../components/appFooter.vue'
defineComponent({
  name: 'ABOUT'
})
</script>

<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  height: calc(100vh - 9.1rem);
  overflow-y: scroll;
}

.box {
  margin-left: 17%;
  height: 100%;
  width: 70%;
  .title {
    display: flex;
    padding: 2rem 0;
    align-items: center;
    .bigGreen {
      width: 0.5625rem;
      height: 2.5rem;
      border-radius: 2px;
      background: #40cb75;
      margin-top: 4px;
    }
    .titleText {
      font-family: Source Han Sans CN;
      font-size: 2.25rem;
      color: #3d3d3d;
      margin-left: 2.875rem;
    }
    .greenGo {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
  .text {
    font-family: Source Han Sans CN;
    font-size: 24px;
    color: #3d3d3d;
    padding-bottom: 40px;
    text-indent: 2em;
  }
}
</style>
