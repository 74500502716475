// store.js
import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state() {
    return {
      isAuthenticated: false, // 用户登录状态
      token: null,
    };
  },
  mutations: {
    SET_AUTHENTICATED(state, status) {
      state.isAuthenticated = status;
    },
  },
  actions: {
    async verifyCredentials({ commit }, { phone, code }) {
      try {
        const response = await axios.post('/api/verify', { phone, code });
        if (response.data.success) {
          commit('SET_AUTHENTICATED', true);
          // 处理登录成功的其他逻辑...
        }
      } catch (error) {
        console.error('Login error:', error);
        // 处理登录失败的逻辑...
      }
    },
  },
});