<template>
  <div class="page">
    <!-- <div class="carousel-example">
      <CarouselExample />
    </div> -->
    <div class="content">
      <div>
        <div class="title">
          <div class="bigGreen"></div>
          <div class="titleText">精选直播课</div>
        </div>
        <div class="live">
          <div class="liveLeft">
            <img class="liveImg" src="../assets/home.png" alt />
            <div class="leftBottom">
              <div class="leftBottomLeft">
                <img class="teacherImg" src="../assets/logo.png" alt />
                <div>宋佳</div>
              </div>
              <div class="leftBottomRight">
                <div>来自课程：2023伏羲医学伏羲医学</div>
                <img class="go" src="../assets/go.png" alt />
              </div>
            </div>
          </div>
          <div class="liveRight">
            <div class="liveListBox">
              <div
                class="liveList"
                v-for="(item,index) in state.liveListData"
                :key="index"
                :style="state.isMouseover && index===state.mouseoverIndex ? 'color:#40cb75':''"
                @mouseover="liveMouseover(index)"
                @mouseleave="liveMouseleave"
              >
                <img v-if="item.inLive===0" class="videoImg" src="../assets/video.png" alt />
                <div class="videoBox" v-if="item.inLive===1">
                  <img class="inlive" src="../assets/inlive.png" alt />
                </div>
                <div style="width: 160px;color: #40cb75;" v-if="item.inLive===1">直播中</div>
                <div style="width: 160px;" v-if="item.inLive===0">{{item.time}}</div>
                <div style="width: 280px;">{{ item.title }}</div>
                <div style="width: 90px;">{{item.name}}</div>
                <div style="width: 60px;" class="btn">未开始</div>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
        <div class="title">
          <div style="display: flex;">
            <div class="bigGreen"></div>
            <div class="titleText">师资力量</div>
          </div>
          <div style="display: flex; margin-left: 76%;">
            <div style="color: #40cb75;">查看更多</div>
            <img class="greenGo" src="../assets/greenGo.png" alt />
          </div>
        </div>
        <div class="teacher">
          <div class="teacherList">
            <img class="image" src="../assets/logo.png" alt />
            <div class="bottom">
              <div style="margin-top: 6px;">
                <span style="font-size: 20px;">宋佳</span>
                <span>/特约讲师</span>
              </div>
              <div class="greenLine"></div>
              <div class="text">医学硕士,12年医考培训</div>
            </div>
          </div>
          <div class="teacherList">
            <img class="image" src="../assets/logo.png" alt />
            <div class="bottom">
              <div style="margin-top: 6px;">
                <span style="font-size: 20px;">毛利</span>
                <span>/特约讲师</span>
              </div>
              <div class="greenLine"></div>
              <div class="text">医学硕士,15年医考培训</div>
            </div>
          </div>
          <div class="teacherList">
            <img class="image" src="../assets/logo.png" alt />
            <div class="bottom">
              <div style="margin-top: 6px;">
                <span style="font-size: 20px;">方丈</span>
                <span>/特约讲师</span>
              </div>
              <div class="greenLine"></div>
              <div class="text">医学硕士,10年医考培训</div>
            </div>
          </div>
          <div class="teacherList">
            <img class="image" src="../assets/logo.png" alt />
            <div class="bottom">
              <div style="margin-top: 6px;">
                <span style="font-size: 20px;">庞刚</span>
                <span>/特约讲师</span>
              </div>
              <div class="greenLine"></div>
              <div class="text">医学硕士,15年医考培训</div>
            </div>
          </div>
          <div class="teacherList">
            <img class="image" src="../assets/logo.png" alt />
            <div class="bottom">
              <div style="margin-top: 6px;">
                <span style="font-size: 20px;">李季</span>
                <span>/特约讲师</span>
              </div>
              <div class="greenLine"></div>
              <div class="text">医学硕士,15年医考培训</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <appFooter></appFooter>
  </div>
</template>

<script setup >
import 'swiper/css'
import { defineComponent, reactive } from 'vue'
import 'vue3-carousel/dist/carousel.css'
// import CarouselExample from '../components/CarouselExample.vue'
import appFooter from '../components/appFooter.vue'

defineComponent({
  name: 'HOME'
})
const state = reactive({
  liveListData: [
    {
      time: '08-10 19:00-21:00',
      title: '药理学第一讲',
      inLive: 1,
      name: '宋佳'
    },
    {
      time: '08-10 19:00-21:00',
      title: '口腔修复学',
      inLive: 1,
      name: '李季'
    },
    {
      time: '08-10 19:00-21:00',
      title: '西药一习题',
      inLive: 0,
      name: '庞刚'
    },
    {
      time: '08-10 19:00-21:00',
      title: '西药二习题',
      inLive: 0,
      name: '庞刚'
    },
    {
      time: '08-10 19:00-21:00',
      title: '中医技能实操',
      inLive: 0,
      name: '宋佳'
    }
  ],
  isMouseover: false,
  mouseoverIndex: ''
})
const liveMouseover = (index) => {
  state.mouseoverIndex = index
  state.isMouseover = true
}
const liveMouseleave = () => {
  state.isMouseover = false
}
</script>

<style scoped  lang="scss">
.page {
  width: 100%;
  height: calc(100vh - 9.1rem);
  background-color: #ededed;
  overflow-y: scroll;
  overflow-x: hidden;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title {
  display: flex;
  padding: 2.5rem 0;
  align-items: center;
  .bigGreen {
    width: 0.5625rem;
    height: 2.5rem;
    border-radius: 2px;
    background: #40cb75;
    margin-top: 4px;
  }
  .titleText {
    font-family: Source Han Sans CN;
    font-size: 2.25rem;
    color: #3d3d3d;
    margin-left: 2.875rem;
  }
  .greenGo {
    width: 1.25rem;
    height: 1.25rem;
  }
}
.live {
  display: flex;
  .liveLeft {
    .liveImg {
      width: 33rem;
      height: 20.1875rem;
      border-radius: 5px 0px 0px 5px;
    }
    .leftBottom {
      display: flex;
      align-items: center;
      color: #ffffff;
      width: 33rem;
      height: 3.5625rem;
      border-radius: 0px 0px 3px 3px;
      background: rgba(0, 0, 0, 0.92);

      .leftBottomLeft {
        display: flex;
        align-items: center;
        width: 15rem;
        .teacherImg {
          width: 2.25rem;
          margin-left: 1.25rem;
          margin-right: 10px;
          height: 2.25rem;
          border-radius: 50%;
        }
      }
      .leftBottomRight {
        width: 18.75rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .go {
          width: 10px;
          height: 16px;
        }
      }
    }
  }
  .liveRight {
    height: 24rem;
    position: relative;
    background-color: #ffffff;
    width: 42.5rem;
    .line {
      position: absolute;
      top: 2.375rem;
      left: 2.375rem;
      width: 1px;
      z-index: 0;
      height: 18.75rem;
      background-color: #ededed;
    }

    .liveListBox {
      position: relative;
      z-index: 1;
      margin-left: 1.875rem;
      .liveList {
        display: flex;
        height: 4.75rem;
        line-height: 4.125rem;
        align-items: center;
        .videoBox {
          width: 18px;
          margin-right: 10px;
          height: 18px;
          border-radius: 50%;
          text-align: center;
          line-height: 18px;
          background: #40cb75;
          .inlive {
            width: 20px;
            height: 10px;
          }
        }
        .videoImg {
          width: 20px;
          height: 20px;
          margin-left: -2px;
          margin-right: 10px;
        }
        .btn {
          width: 3.5rem;
          height: 1.875rem;
          text-align: center;
          line-height: 1.875rem;
          border-radius: 3px;
          color: #3d3d3d;
          background: #f7f7f7;
          border: 1px solid #ededed;
        }
      }
    }
  }
}
.teacher {
  padding-bottom: 60px;
  display: flex;
  width: 75rem;
  overflow: hidden;
  .teacherList {
    margin-right: 14px;
    width: 14.25rem;
    height: 23.125rem;
    background-color: #ffffff;
    .image {
      width: 14.25rem;
      height: 16rem;
      border-radius: 3px;
    }
    .bottom {
      margin-left: 1.25rem;
      width: 86%;
      .greenLine {
        width: 23px;
        height: 3px;
        margin-top: 4px;
        border-radius: 3px;
        background: #40cb75;
      }
      .text {
        font-family: Source Han Sans CN;
        font-size: 14px;
        color: #9fa19f;
        padding: 14px 0;
      }
    }
  }
}
</style>
