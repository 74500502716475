
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';
import about from './view/about.vue';
import downLoadAPP from './view/downLoadAPP.vue';
import home from './view/home.vue';
import Login from './view/Login.vue';
// import video from './view/video.vue';
// import menu from './view/menu.vue';
import courses from './view/Courses.vue';
import store from '@/store'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: home, name: 'home',

    },
    {
      path: '/login',
      component: Login,
    },
    // { path: '/video', component: video,name:'video' },
    {
      path: '/downLoadAPP',
      component: downLoadAPP, name: 'downLoadAPP'
    },
    {
      path: '/courses', component: courses, name: 'courses',
      beforeEnter: (to, from, next) => {
        console.log('Checking authentication...');
        if (!store.state.isAuthenticated) {
          console.log('用户未登录');
          // 用户未登录，弹出登录框
          next('/login');
        } else {
          next();
        }
      }
    },
    {
      path: '/about',
      component: about, name: 'about'
    },
    // { path: '/menu', component: menu ,name:'menu'},
  ],
});

const app = createApp(App);
app.use(ElementPlus)
app.use(router);
app.mount('#app');
