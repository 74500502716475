import axios from 'axios';
import { getToken } from './api';
export function request(config) {
  const token = getToken('token') || ''
  console.log(token);
  // 创建axios的实例
  const instance = axios.create({
    baseURL: '',
    timeout: 10000,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    //   // 'Authorization': "bearer " + '',
    // },
    headers: {
      'content-type': 'application/json', // 默认值
      Authorization: 'bearer ' + token,
    },
  })
  // 请求拦截器配置
  instance.interceptors.request.use(
    (config) => {
      // config.headers.Authorization = window.sessionStorage.getItem('token')
      return config
    },
    (error) => {
      return Promise.error(error)
    }
  )
  // 响应拦截器配置
  instance.interceptors.response.use(
    (response) => {
      return response.data
    },
    (error) => {
      switch (error.request.status) {
        case 401:
          console.log('无权访问')
          break
        case 403:
          console.log('token过期啦')
          break
        case 404:
          console.log('404啦')
          break
        default:
          return Promise.reject(error)
      }
      return Promise.reject(error)
    }
  )
  // 发送真正的网络请求
  return instance(config)
}
export default request
