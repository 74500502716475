<template>
  <div class="video-list">
    <div class="video-row" v-for="videoGroup in videoRows" :key="videoGroup[0].id">
      <div v-for="video in videoGroup" :key="video.id" class="video-item">
        <div class="video-container">
          <video :src="video.videoUrl" controls class="video-player"></video>
        </div>
        <div class="video-info">
          <h3>{{ video.name }}</h3>
          <p>{{ video.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import store from '../store';

  export default {
    name: 'VideoList',
    props: {
      categoryId: {
        type: String,
        required: true
      },
      currentCourseName: {
        type: String,
        required: true
      },
      reload: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        videos: [],
      };
    },
    watch: {
      // 当 reload 变化时重新加载视频列表
      reload(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchVideos(this.categoryId);
        }
      }
    },

    created() {
      this.fetchVideos();
    },

    computed: {
      videoRows() {
      // 将 videos 数组分成每组三个元素的数组
      return this.videos.reduce((rows, video, index) => {
        if (index % 3 === 0) {
          rows.push([]);
        }
        rows[rows.length - 1].push(video);
        return rows;
      }, []);
    },
    },

    methods: {
      async fetchVideos() {
        if (!this.categoryId) {
          console.log('Invalid category ID');
          return;
        }
        this.videos = await this.getVideosByCategory(this.categoryId);
      },
      reloadVideos() {
        this.fetchVideos();
      },
      async getVideosByCategory(categoryId) {
      console.log('Fetching videos for category:', categoryId);
      try {
        const response = await axios.get(`/course/categories/${categoryId}/videos`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + store.state.token
          }
        });
        console.log("视频", response.data.data);
        return response.data.data;
      } catch (error) {
        console.error('error', error);
        if (error.response) {
          // 请求已发出，但服务器响应的状态码不在 2xx 范围内
          console.error(error.response.data);
          console.error(error.response.status);
        } else if (error.request) {
          // 请求已发出但没有收到响应
          console.error(error.request);
        } else {
          // 发生了触发请求错误的问题
          console.error('Error', error.message);
        }
        return error;
      }
    },
    },
  };
  </script>
  
  <style scoped>
.video-list {
  display: flex;
  flex-wrap: wrap;
}

.video-row {
  display: flex;
  width: 100%;
}

.video-item {
  flex: 1;
  padding: 10px; /* 根据需要调整间距 */
  box-sizing: border-box;
}

.video-container {
  width: 100%;
  /* 保持视频的纵横比，例如 16:9 */
  padding-top: 56.25%;
  position: relative;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* 
.video-item {
  flex: 1 1 16.66%; 
  padding: 10px;
  box-sizing: border-box; 
}

.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow: hidden;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  outline: none; 

.video-info {
  margin-top: 5px;
  text-align: center; 
}

.video-item:hover .video-cover {
  opacity: 0.5;
} */
</style>