import http from '../utils/http'

export function passwordLogin(params) {
  return http.post('/user_center/v1/login_with_password', params)
}
export function sendVerificationCode(params) {
  return http.post(
    `/user_center/v1/verification_code?phoneNumber=${params.phoneNumber}`
  )
}
export function codeLogin(params) {
  return http.post(
    `/user_center/v1/login_with_code`,params
  )
}
