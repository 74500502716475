<template>
  <div class="appFooter">
    <div class="content">
      <div class="left">
        <img class="img" src="../assets/logo.png" alt />
        <div class="text">
          <div style="font-size: 36px;">伏羲医学</div>
          <div style="color: #969696; margin-top: 10px;">弘 扬 中 医 文 化</div>
          <div style="color: #969696;">引 领中 医 全 球 化</div>
        </div>
      </div>
      <div class="center">
        <div style="margin-right: 60px;">
          <img class="img" src="../assets/appCode.png" alt />
          <p>伏羲医学APP</p>
        </div>
        <div>
          <img class="img" src="../assets/weixin.png" alt />
          <p>微信小程序</p>
        </div>
      </div>
      <div class="right">
        <div style="font-size: 20px;">伏羲医学客服电话:</div>
        <div style="font-size: 36px; padding: 10px 0;">190-6501-0986</div>
        <div style="color: #969696;">地址: 深圳市宝安区海天路15号宝中卓越时代广场A座1401</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="box">
      <img class="icon" src="../assets//icon.png" alt />
      <div>粤公网安备 44030602007894号 粤ICP备2023081949号</div>
    </div>
    <div>Copyright © 2023 - 2023 FUXIYIXUE. All Rights Reserved. 深圳上医人工智能科技有限公司 版权所有</div>
  </div>
</template>

<script>
export default {
  name: 'appFooter',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.appFooter {
  /* position: fixed; */
  font-size: 14px;
  /* bottom: 0; */
  width: 100%;
  height: 250px;
  background: #eff3f5;
  text-align: center;
}
.content {
  padding-top: 30px;
  width: 78%;
  display: flex;
  margin-left: 11%;
  justify-content: space-around;
}
.img {
  width: 90px;
  height: 90px;
}
.left {
  display: flex;
}
.center {
  display: flex;
}
.right {
  width: 245px;
  text-align: left;
}
.line {
  width: 100%;
  height: 1px;
  background: #d5d5d5;
}
.text {
  margin-left: 20px;
}
.box {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}
.icon {
  width: 15px;
  margin-top: 2px;
  margin-right: 2px;
  height: 15px;
}
</style>
