<template>
  <div class="page">
    <div class="contentBox">
      <div class="content">
        <div class="contentLeft">
          <div class="top">
            <div class="title">伏羲医学</div>
            <div class="topText">GPT真题、GPT练习、GPT预测卷、高频考题、名师直播全面上线</div>
            <div class="topText">伏羲医学-题库升级</div>
            <div class="topText">乐在其中，尽享成功！</div>
          </div>
          <div class="itemBox">
            <div class="item" v-for="(item,index) in itemData" :key="index">
              <div class="imgBox">
                <img :src="item.img" alt />
              </div>
              <div style="margin-left: 10px;">
                <div class="itemTitle">{{ item.title }}</div>
                <div class="itemText">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="contentRight">
          <img src="../assets/phone.png" alt />
        </div>
      </div>
    </div>
    <div class="downLoad">
      <div class="title">
        <div style="display: flex;">
          <div class="bigGreen"></div>
          <div class="titleText">软件下载</div>
        </div>
      </div>
      <div class="downLoadBox">
        <div class="downLoadImgBox">
          <img class="code" src="../assets/appCode.png" alt />
          <div class="codeName">APP</div>
        </div>
        <div class="downLoadImgBox">
          <img class="code" src="../assets/weixin.png" alt />
          <div class="codeName">小程序</div>
        </div>
      </div>
    </div>
    <appFooter></appFooter>
  </div>
</template>

<script setup>
import { defineComponent, reactive } from 'vue';
import appFooter from '../components/appFooter.vue';
defineComponent({
  name: 'downLoadAPP'
})
const itemData = reactive([
  {
    title: '题库',
    img: require('@/assets/book.png'),
    text: '优质题库，免费共享'
  },
  {
    title: '直播',
    img: require('@/assets/live.png'),
    text: '名师入驻，免费直播'
  },
  {
    title: '题目解析',
    img: require('@/assets/QA.png'),
    text: '视频解析，内容详细'
  }
])
</script>

<style scoped lang="scss">
.page {
  width: 99vw;
  height: calc(100vh - 9.1rem);
  overflow-y: scroll;

  .contentBox {
    background-color: #ededed;
    width: 100%;
    .content {
      display: flex;
      margin-left: 17%;
      padding: 60px 0;
      width: 70%;
      justify-content: space-between;
      .contentLeft {
        .top {
          .title {
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-size: 36px;
          }
          .topText {
            margin-top: 10px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-size: 24px;
          }
        }
        .itemBox {
          padding-top: 30px;
          .item {
            display: flex;
            margin-top: 50px;
            .imgBox {
              width: 72px;
              height: 72px;
              text-align: center;
              // line-height: 72px;
              box-sizing: border-box;
              border: 2px solid #40cb75;
              border-radius: 50%;
              img {
                width: 37px;
                margin-top: 16px;
                height: 36px;
              }
            }

            .itemTitle {
              font-family: Source Han Sans CN;
              font-size: 24px;
              color: #3d3d3d;
            }
            .itemText {
              margin-top: 12px;
              font-size: 20px;
              color: #cacaca;
            }
          }
        }
      }
      .contentRight {
        img {
          width: 316px;
          height: 628px;
        }
      }
    }
  }
  .downLoad {
    margin-left: 17%;
    width: 70%;
    .title {
      display: flex;
      padding: 2.5rem 0;
      align-items: center;
      .bigGreen {
        width: 0.5625rem;
        height: 2.5rem;
        border-radius: 2px;
        background: #40cb75;
        margin-top: 4px;
      }
      .titleText {
        font-family: Source Han Sans CN;
        font-size: 2.25rem;
        color: #3d3d3d;
        margin-left: 2.875rem;
      }
      .greenGo {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    .downLoadBox {
      display: flex;
      margin-left: 40px;
      width: 80%;
      justify-content: space-between;
      .downLoadImgBox {
        text-align: center;

        .code {
          width: 263px;
          height: 251px;
          border-radius: 5px;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
        }
        .codeName {
          font-family: Source Han Sans CN;
          font-size: 36px;
          color: #3d3d3d;
          margin-top: 20px;
          padding-bottom: 100px;
        }
      }
    }
  }
}
</style>
