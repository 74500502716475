<template>
  <appHeader />
  <router-view></router-view>
</template>

<script>
// import appFooter from './components/appFooter.vue';
import appHeader from './components/appHeader.vue';

export default {
  name: 'App',
  components: {
    appHeader,
    // appFooter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}
</style>
