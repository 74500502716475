<template>
  <div class="container">
    <div class="sidebar">
      <div class="course-category-list">
        <li v-for="category in categories" :key="category.id" class="category-item">
          <label @click="toggleCategory(category)">
            <input type="checkbox" :checked="expandedCategories.includes(category.id)" />
            <span v-if="expandedCategories.includes(category.id)">-</span>
            <span v-else>+</span>
            {{ category.name }}
          </label>
          <ul v-if="expandedCategories.includes(category.id)">
            <li v-for="subCategory in category.children" :key="subCategory.id" class="subcategory-item" @click="selectSubCategory(subCategory)">
              <label>
                {{ subCategory.name }}
              </label>
            </li>
          </ul>
        </li>
      </div>
    </div> 
    <div class="main-content">
      <div class="course-videos">
        <video-list :category-id="selectedSubCategoryId" :reload="reloadKey" v-if="selectedSubCategoryId" />
      </div>
    </div>
    <app-footer />
  </div>
</template>

<script>
// eslint-disable-next-line
import appFooter from '../components/appFooter.vue';
import axios from 'axios';
import store from '../store';
import VideoList from './VideoList.vue';

axios.defaults.baseURL = 'https://www.fuxiyixue.cn';

export default {
  name: 'CoursePage',
  components: {
    VideoList
  },
  data() {
    return {
      reloadKey: 0,
      categories: [],
      expandedCategories: [], // 用于追踪已展开的分类
      videos: [],
      selectedCategoryId: null,
      selectedSubCategoryId: null,
    };
  },
  created() {
    this.loadCategories();
  },
  methods: {
    async loadCategories() {
      console.log('Fetching categories...');
      try {
        this.categories = await this.getCourseCategories();
        console.log('课程分类:', this.categories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    },
    toggleCategory(category) {
      console.log("切换分类:", category);
      if (!category || category.id === undefined) {
        console.error('Category id is undefined', category);
        return;
      }

      const index = this.expandedCategories.indexOf(category.id);
      if (index > -1) {
        // 如果已经展开，从数组中移除
        this.expandedCategories.splice(index, 1);
      } else {
        // 否则，添加到数组中
        // console.log("展开分类", category);
        this.expandedCategories.push(category.id);
        // console.log("展开", this.expandedCategories);
      }
    },
    selectCategory(category) {
      console.log('Selected category:', category);
      this.selectedCategoryId = category.id;
    },

    selectSubCategory(subCategory) {
      console.log('Selected subCategory:', subCategory);
      this.selectedSubCategoryId = subCategory.id;
      this.reloadKey += 1;
    },

    async getCourseCategories() {
      try {
        // store.state.token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTU4MjIwMTYsImp0aSI6IjJmQTNOaVA5eE5LRHdqb2E4U0JBZGhSWjByZCIsInVzZXJJRCI6MzJ9.53xFeBOmxMey6hg769VbXHeFJMYA9Lw0hMbBh3wSfrM'
        const response = await axios.get('/course/categories', {
          headers: {
            // 'User-Agent': 'Apifox/1.0.0 (https://apifox.com)'
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + store.state.token
          }
        });
        let ret = response.data.data
        console.log("data=", ret);
        return ret;
      } catch (error) {
        console.error('error', error);
        if (error.response) {
          // 请求已发出，但服务器响应的状态码不在 2xx 范围内
          console.error("data=", error.response.data);
          console.error("status=", error.response.status);
        } else if (error.request) {
          // 请求已发出但没有收到响应
          console.error("error.request=", error.request);
        } else {
          // 发生了触发请求错误的问题
          console.error('Error msg=', error.message);
        }
        return error;
      }
    },

    async getVideosByCategory(categoryId) {
      console.log('Fetching videos for category:', categoryId);
      try {
        const response = await axios.get(`/course/categories/${categoryId}/videos`, {
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + store.state.token
          }
        });
        console.log("视频", response.data.data);
        return response.data.data;
      } catch (error) {
        console.error('error', error);
        if (error.response) {
          // 请求已发出，但服务器响应的状态码不在 2xx 范围内
          console.error(error.response.data);
          console.error(error.response.status);
        } else if (error.request) {
          // 请求已发出但没有收到响应
          console.error(error.request);
        } else {
          // 发生了触发请求错误的问题
          console.error('Error', error.message);
        }
        return error;
      }
    },
  },
};
</script>

<style scoped>
/* .container {
  display: flex;
  flex-direction: row;
}
*/
.container {
  display: flex; /* 使用 Flexbox 布局 */
}

.sidebar {
  flex: 0 0 20%; /* 侧边栏占据一部分空间 */
  padding-right: 20px; /* 右侧边距 */
}

.main-content {
  flex: 3; /* 主内容占据更多空间 */
}
/* .course-categories {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
} */
.category-item,
.subcategory-item {
  font-size: 16px; /* 设置课程分类和子分类的字体大小为20px */
}

.subcategory-item {
  padding: 5px; /* 为子分类项添加一些内边距 */
  cursor: pointer;
}

.subcategory-item:hover {
  background-color: #f0f0f0; /* 鼠标悬停时的背景颜色 */
}

.selected {
  background-color: #8eaffe; /* 子分类被选中时的背景颜色 */
  font-weight: bold; /* 字体加粗 */
}

/*
.category-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.category-item {
  width: calc(50% - 10px); 
  margin-right: 10px;
}

.category-item:last-child {
  margin-right: 0;
}
.subcategory-item {
  cursor: pointer;
  padding: 5px;
  transition: background-color 0.3s;
}

.subcategory-item:hover {
  background-color: #eaeaea;
}

.selected {
  background-color: #d8eaffe; 
  font-weight: bold;
}

.video-item {
  cursor: pointer;
} */
</style>